<template>
  <div style="height: 100%">
    <cashier-transfer-dialog
      :user="receiver"
      @closeDialog="close"
      v-if="step === 1"
      @setStep="setStep"
    />
    <cashier-transfer-scan
      @closeDialog="close"
      v-show="step === 0"
      @setStep="setStep"
    />
    <cashier-transfer-completed
      :user="receiver"
      @closeDialog="close"
      v-if="step === 2"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      step: 0,
    };
  },
  components: {
    CashierTransferDialog: () =>
      import("@/components/Dialogs/CashierTransferDialog"),
    CashierTransferCompleted: () =>
      import("@/components/Dialogs/CashierTransferCompleted"),
    CashierTransferScan: () =>
      import("@/components/Dialogs/CashierTransferScan"),
  },
  props: {
    user: {
      type: [Object],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      receiver: "wallet/transferReceiver",
    }),
  },
  methods: {
    close() {
      this.clearTransferReceiver()
      this.$emit("closeDialog");
    },
    setStep(e) {
      this.step = e;
      if (this.step === 0) {
        this.clearTransferReceiver()
      }
    },
    clearTransferReceiver() {
      this.$store.commit("wallet/setTransferReceiver", null)
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  color: var(--v-trolleyGrey-base) !important;
}
.modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
